import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageGrid from "../components/ImageGrid/ImageGrid"
import ImageWithText from "../components/ImageWithText/ImageWithText"

const PoliticsPage = ({ data }) => {

  // Grab variable from .md file

  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  // Large BG Images
  const earlyAgeBGContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Early Age')
  const politicalEngagementContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Political Engagement')
  const allanCranstonContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Alan Cranston')
  const crossingTheAisleContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Across The Aisle')
  const grandAvenueContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Grand Avenue')
  const publicEdPolicyContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Public Education Policy')
  const futureOfDemocracyContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Future Of Our Democracy')

  // Two Column Sections
  const twoPoliticalEngagement = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'politicalEngagement')
  const twoPoliticalEngagementTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'politicalEngagementTop')
  const twoAllaCranstonRace = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'allanCranstonRace')
  const twoAllaCranstonRaceTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'allanCranstonRaceTop')
  const twoColumncranstonCampaign = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'cranstonCampaign')
  const twoPoliticalRelationships = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'politicalrelationships')
  const twoReimagination = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'reimagination')
  const twoEducationReform = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'educationReform')
  const twoElection = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'education')

  // Image Grid Component

  const germanySurrenders = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'germanySurrenders')

  // Image With Text
  const allCranstonComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'allanCranston')
  const colinPowellComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'colinPowell')
  const markRidleyThomasEBComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'markRidleyThomasEB')
  const bradleyGoreComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'bradleyGore')
  const carterBloombergComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'carterBloomberg')
  const georgeBushComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'georgebush')
  const clintonLetterComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'clintonletter')
  const clintonsEbComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'clintonsEb')
  const richardReordanComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'richardReordan')
  const whiteHouseLetterComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'whiteHouseLetter')
  const partneringComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'partnering')
  const nationalLevelComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'nationalLevel')
  const EBQuote = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'EBQuote')


  // Hero Text Positions
  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200",
          text1: {
            value: "That Man",
            classes: "sixty"
          },
          text2: {
            value: "",
            classes: "one"
          }
      },
      TextRow2: {
        classes: "mb-350 center",
          text1: {
            value: "Will",
            classes: "twenty"
          },
          text2: {
            value: "Be",
            classes: "twenty Leftauto"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two empty-height",
      TextRow1: {
        classes: "empty",
          text1: {
            value: "",
            classes: ""
          },
          text2: {
            value: " ",
            classes: ""
          }
      },
      TextRow2: {
        classes: "empty",
          text1: {
            value: " ",
            classes: ""
          },
          text2: {
            value: " ",
            classes: ""
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "President",
            classes: "forty"
          },
          text2: {
            value: "One Day",
            classes: "forty Leftauto"
          }
      },
    }
  }
  return (
    <>
      <Seo title={"Eli Broad | Political Engagement"} description={"From an early age, Eli Broad understood that government plays a powerful role in determining the life experience of its citizens."} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={earlyAgeBGContent} />

      <BGImg content={politicalEngagementContent} />

      <TwoColumn content={twoPoliticalEngagementTop} type="top" />

      <TwoColumn content={twoPoliticalEngagement} type="bottom"/>

      <ImageGrid content={germanySurrenders} />

      <BGImg content={allanCranstonContent} />

      <TwoColumn content={twoAllaCranstonRaceTop} type="top" />

      <TwoColumn content={twoAllaCranstonRace} type="bottom" />

      <ImageWithText content={allCranstonComponent} alignTop={true} imageFloat={true} />

      <TwoColumn content={twoColumncranstonCampaign} noPadding={true} />

      <BGImg content={crossingTheAisleContent} />

      <TwoColumn content={twoPoliticalRelationships}/>

      <ImageWithText content={colinPowellComponent} headerContent={true} />

      <ImageWithText content={markRidleyThomasEBComponent} headerContent={true} />

      <ImageWithText content={bradleyGoreComponent} headerContent={true} />

      <ImageWithText content={carterBloombergComponent} headerContent={true} />

      <ImageWithText content={georgeBushComponent} headerContent={true} />

      <ImageWithText content={clintonLetterComponent} headerContent={true} />

      <ImageWithText content={clintonsEbComponent} headerContent={true} />

      <BGImg content={grandAvenueContent} />

      <TwoColumn content={twoReimagination}/>

      <ImageWithText content={richardReordanComponent} headerContent={true} />

      <BGImg content={publicEdPolicyContent} />

      <TwoColumn content={twoEducationReform}/>

      <ImageWithText content={whiteHouseLetterComponent} headerContent={true} />

      <ImageWithText content={partneringComponent} />

      <ImageWithText content={nationalLevelComponent} headerContent={true} />

      <BGImg content={futureOfDemocracyContent} />

      <TwoColumn content={twoElection}/>

      <ImageWithText content={EBQuote} headerContent={true} />

    </>
  )
}

export default PoliticsPage

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "politics"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "politics"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "politics"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "politics"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "politics"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
  }
`


